<template>
  <div style="background: #fff; padding-bottom: 20px; width: 100%" v-loading="editDialogLoading">
    <div class="businessEdit" v-if="isBusinessLog && permission(['VIEW_BUSINESS_LOG'])">
      <el-button type="success" @click="businessIsShow = true">操作记录</el-button>
    </div>
    <el-form
      class="column3 form_box"
      ref="form"
      v-show="activeName == 0"
      :model="form"
      :rules="rules"
    >
      <div class="big_box_div" style="margin-right: 10px">
        <el-descriptions title="商务基本信息" direction="vertical" :column="3" border>
          <template slot="title">
            <span>商务基本信息</span>
            <span v-if="disbursementShow && form.editFlag == 1" class="title_right"
              >该商务为修改后重新审批</span
            >
            <el-button size="mini" v-if="isProject" type="success" @click="leaveFor"
              >前往该商务详情信息</el-button
            >
          </template>
          <el-descriptions-item :span="3">
            <template slot="label">
              <!-- <span style="color: red">*</span> -->
              商务名称
            </template>
            <el-form-item
              label-width="0px"
              prop="businessName"
              ref="businessName"
              style="width: 100%"
            >
              <!-- <el-input :disabled="disabled" v-model="form.businessName"> </el-input> -->
              <div @mouseover="mouseoverBusinessName" @mouseout="mouseoutBusinessName">
                <el-autocomplete
                  :fetch-suggestions="businessQuerySearch"
                  :disabled="disabled"
                  v-model="form.businessName"
                  @select="onBusinessNameSelect"
                >
                </el-autocomplete>
              </div>
              <span v-show="isBusinessNameTooltip" class="businessNameTooltip">{{
                form.businessName
              }}</span>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item
            label-class-name="businessOutSource"
            content-class-name="businessOutSource"
          >
            <template slot="label">
              <span style="color: #000">商务创建人</span>
            </template>
            <div class="input_style">
              {{ getLinshi_initiator(this.form.initiatorId) }}
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="信息提供人">
            <!-- <div class="input_style">
              {{ getLinshi_initiator(this.form.provideId) }}
            </div> -->
            <!-- <el-input
                v-model="linshi_Name"
                @focus="linshi_show = true"
                placeholder="请输入"
                :disabled="disabled">
                <el-button
                  slot="append"
                  :disabled="disabled"
                  icon="el-icon-user"
                  @click="linshi_show = true"></el-button>
              </el-input> -->
            <template slot="label">
              <span style="color: red">{{ options.list_check ? '*' : '' }}</span> 信息提供人
            </template>
            <el-form-item label-width="0px" prop="provideId" ref="provideId" style="width: 100%">
              <SelectDialog @selectDataChange="linshiTreeFn">
                <el-input
                  slot="button"
                  :readonly="true"
                  :disabled="disabled"
                  style="cursor: pointer"
                  v-model="linshi_Name"
                  placeholder="请选择信息提供人"
                ></el-input>
              </SelectDialog>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <span style="color: red">{{ options.list_check ? '*' : '' }}</span> 项目承接公司
            </template>
            <el-form-item ref="companyType" prop="companyType" style="width: 100%">
              <Dictionary
                style="width: 100%"
                :disabled="disabled"
                v-model="form.companyType"
                code="COMPANY_TYPE"
                placeholder="请选择项目承接公司"
              />
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item style="width: 100%">
            <template slot="label"> <span style="color: red">*</span> 商务实际开始时间 </template>
            <el-form-item
              label-width="0px"
              prop="businessStartDate"
              ref="businessStartDate"
              style="width: 100%"
            >
              <el-date-picker
                v-model="form.businessStartDate"
                :disabled="form.list_s == 2"
                value-format="timestamp"
                type="date"
                :clearable="false"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item :span="2">
            <template slot="label"> <span style="color: red">*</span> 业主单位 </template>
            <el-form-item style="width: 100%" prop="ownerUnit" ref="ownerUnit">
              <el-autocomplete
                :disabled="disabled"
                :fetch-suggestions="querySearch"
                v-model="form.ownerUnit"
                @select="onInputSelect"
              >
              </el-autocomplete
            ></el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="经办人">
            <template slot="label"> <span style="color: red">*</span> 经办人 </template>
            <el-form-item prop="contacts" ref="contacts" style="width: 100%">
              <el-input :disabled="disabled" v-model.trim="form.contacts"></el-input>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="经办人职务">
            <el-form-item prop="post" style="width: 100%">
              <el-input :disabled="disabled" v-model.trim="form.post"> </el-input></el-form-item
          ></el-descriptions-item>
          <el-descriptions-item label="经办人电话">
            <template slot="label"> <span style="color: red">*</span> 经办人电话 </template>
            <el-form-item
              label-width="0px"
              ref="contactsPhone"
              prop="contactsPhone"
              style="width: 100%; min-width: 180px; padding: 0"
            >
              <el-input :disabled="disabled" v-model.trim="form.contactsPhone"> </el-input
            ></el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="决策人">
            <el-form-item prop="decisionMaker" style="width: 100%">
              <el-input :disabled="disabled" v-model.trim="form.decisionMaker">
              </el-input></el-form-item
          ></el-descriptions-item>
          <el-descriptions-item label="决策人职务">
            <el-form-item prop="decisionMakerPost" style="width: 100%">
              <el-input :disabled="disabled" v-model.trim="form.decisionMakerPost">
              </el-input></el-form-item
          ></el-descriptions-item>
          <el-descriptions-item label="决策人电话">
            <el-form-item
              label-width="0px"
              prop="decisionMakerContactsPhone"
              ref="decisionMakerContactsPhone"
              style="width: 100%; min-width: 180px; padding: 0"
            >
              <el-input :disabled="disabled" v-model.trim="form.decisionMakerContactsPhone">
              </el-input>
            </el-form-item>
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"> 招标方式 </template>
            <el-form-item prop="bidWay" style="width: 100%">
              <!-- <Dictionary
              style="width: 100%"
              :disabled="disabled"
              v-model="form.bidWay"
              code="BUSINESS_TOUBIAO"
              placeholder="请选择招标方式"
            /> -->
              <el-select v-model="form.bidWay" placeholder="请选择" :disabled="disabled">
                <el-option
                  v-for="item in dictData.bidwayOptions"
                  :key="item.dictVal"
                  :label="item.dictName"
                  :value="item.dictVal"
                >
                </el-option> </el-select
            ></el-form-item>
          </el-descriptions-item>

          <el-descriptions-item label="招投标费用（元）">
            <el-form-item prop="tenderFee" style="width: 100%">
              <!-- <el-input
                :disabled="true"
                @input="inputFn('tenderFee')"
                @focus="focusFn('tenderFee')"
                @blur="blurFn('tenderFee')"
                v-model="form.tenderFee"
              >
              </el-input> -->
              {{ form.tenderFee | applyAmount }}
            </el-form-item></el-descriptions-item
          >
          <el-descriptions-item label="招投标费用大写"
            >{{ tenderFeeChinese }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 预计合同金额(元) </template>
            <el-form-item prop="predictMoney" ref="predictMoney" style="width: 100%">
              <el-input
                :disabled="disabled"
                @input="inputFn('predictMoney')"
                @focus="focusFn('predictMoney')"
                @blur="blurFn('predictMoney')"
                v-model="form.predictMoney"
              >
              </el-input></el-form-item
          ></el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <span style="color: red">{{ options.list_check ? '*' : '' }}</span> 我方合同金额(元)
            </template>
            <el-form-item prop="ourContractAmount" ref="ourContractAmount" style="width: 100%">
              <el-input
                :disabled="disabled"
                @input="inputFn('ourContractAmount')"
                @focus="focusFn('ourContractAmount')"
                @blur="blurFn('ourContractAmount')"
                v-model="form.ourContractAmount"
              >
              </el-input></el-form-item
          ></el-descriptions-item>
          <!-- <el-descriptions-item label="我方合同金额大写"
            >{{ ourContractAmountChinese }}
          </el-descriptions-item> -->
        </el-descriptions>

        <el-descriptions style="margin-top: 5px" direction="vertical" :column="1" border>
          <el-descriptions-item>
            <template slot="label">
              <span style="color: red">{{
                (options.isRequire || options.list_check) && form.externalPartnere === 'HAVE'
                  ? '*'
                  : ''
              }}</span>
              商务说明
            </template>
            <el-form-item label-width="0px" prop="content" ref="content" style="width: 100%">
              <el-input
                type="textarea"
                v-model="form.content"
                placeholder="请输入"
                :disabled="disabled"
              ></el-input>
            </el-form-item>
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions
          style="margin-top: 10px"
          title="税率"
          direction="vertical"
          :column="5"
          border
        >
          <el-descriptions-item label="我方合同金额(元)" :span="1">
            <el-input
              :disabled="disabled"
              @input="inputFn('ourContractAmount')"
              @focus="focusFn('ourContractAmount')"
              @blur="blurFn('ourContractAmount')"
              v-model="form.ourContractAmount"
            >
            </el-input
          ></el-descriptions-item>
          <el-descriptions-item label="税费类型" :span="1">
            <template slot="label">
              税费类型
              <el-button
                size="mini"
                type="warning"
                style="margin-left: 10%; padding: 5px"
                @click="showTaxInfo"
                >税率类型说明</el-button
              >
            </template>
            <el-form-item prop="taxType" ref="taxType" style="width: 100%">
              <Dictionary
                :disabled="disabled"
                v-model="form.taxType"
                code="TAX_TYPE"
                placeholder="请选择税费类型"
                :filterable="true"
                @change="onChangeTaxtype"
            /></el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="增值税类型" :span="2">
            <template slot="label">
              <span style="color: red">{{ options.list_check ? '*' : '' }}</span> 增值税类型
              <el-button
                size="mini"
                type="warning"
                style="margin-left: 10%; padding: 5px"
                @click="showVatTypesShow"
                >增值税类型说明</el-button
              >
            </template>
            <el-form-item prop="vatType" ref="vatType" style="width: 100%">
              <el-select
                v-model="form.vatType"
                :disabled="disabled || form.taxType == 'THIRD_PARTY_TAX'"
                @change="dataUpdate"
                placeholder="请选择增值税类型"
                class="ipt_width"
                style="width: 100%"
              >
                <el-option
                  v-for="item in zzsList"
                  :key="item.id"
                  :label="item.vatName"
                  :value="item.vatType"
                >
                </el-option> </el-select
            ></el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="增值税类型详细" :span="1">
            <template slot="label">
              <span style="color: red">{{ options.list_check ? '*' : '' }}</span> 增值税类型详细
            </template>
            <el-form-item prop="vatData" ref="vatData" style="width: 100%">
              <VatDictionary
                style="width: 100%"
                valType="Data"
                :disabled="disabled || form.taxType == 'THIRD_PARTY_TAX'"
                v-model="form.vatData"
                :clearable="false"
                :code="form.vatType"
                placeholder="请选择增值税类型详细"
              />
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 增值税税率(%) </template>
            <el-form-item style="width: 100%">
              <VatDictionary
                style="width: 100%"
                valType="TaxRate"
                :disabled="disabled || form.taxType == 'THIRD_PARTY_TAX'"
                v-model="form.vatRate"
                :code="form.vatData"
                :clearable="false"
                @optionsChange="optionsChange"
                placeholder="请选择增值税税率"
            /></el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="增值税">
            <span v-if="j_configcomp">{{ j_configcomp | applyAmount }} （元）</span>
            <span v-else>0 （元）</span>
          </el-descriptions-item>
          <el-descriptions-item label="附加税税率">12%</el-descriptions-item>
          <el-descriptions-item label="附加税">
            <span v-if="j_actualAdditionalTax"
              >{{ j_actualAdditionalTax | applyAmount }} （元）</span
            >
            <span v-else>0 （元）</span>
          </el-descriptions-item>
          <el-descriptions-item label="印花税税率">0.03%</el-descriptions-item>
          <el-descriptions-item label="印花税">
            <span v-if="j_actualStampTax">{{ j_actualStampTax | applyAmount }} （元）</span>
            <span v-else>0 （元）</span>
          </el-descriptions-item>
          <el-descriptions-item label="税费">
            <span v-if="taxesFee">{{ taxesFee | applyAmount }} （元）</span>
            <span v-else>0 （元）</span>
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div class="big_box_div" style="margin-left: 10px">
        <el-descriptions title="业务类型" direction="vertical" :column="3" border>
          <el-descriptions-item>
            <template slot="label">
              <span style="color: red">*</span>
              商务类型
            </template>
            <el-form-item
              label-width="0px"
              ref="businessType"
              style="width: 100%"
              prop="businessType"
            >
              <Dictionary
                :disabled="disabled"
                v-model="form.businessType"
                code="BUSINESS_TYPE"
                placeholder="请选择商务类型"
              />
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <span style="color: red">{{ options.list_check ? '*' : '' }}</span>
              业务种类
              <el-button
                size="mini"
                type="warning"
                style="margin-left: 10%; padding: 5px"
                @click="showBusinessLinesIsShow"
                >业务种类说明</el-button
              >
            </template>
            <el-form-item prop="businessLines" ref="businessLines" style="width: 100%">
              <Dictionary
                :disabled="disabled"
                v-model="form.businessLines"
                code="BUSINESS_LINES"
                placeholder="请选择业务种类"
                :filterable="true"
            /></el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="项目种类">
            <Dictionary
              :disabled="disabled"
              v-model="form.projectLines"
              :code="form.businessLines"
              :filterable="true"
              placeholder="请选择项目种类"
            />
          </el-descriptions-item>
          <!-- <el-descriptions-item label="项目类型">
            <Dictionary
              :disabled="disabled"
              v-model="form.projectType"
              code="PROJECT_TYPE"
              placeholder="请选择项目类型"
            />
          </el-descriptions-item> -->
          <el-descriptions-item :span="3">
            <template slot="label">
              <span style="color: red">{{ options.list_check ? '*' : '' }}</span>
              项目来源
              <el-button
                size="mini"
                type="warning"
                style="margin-left: 10%; padding: 5px"
                @click="projectSourceIsShow = true"
                >项目来源说明</el-button
              >
            </template>
            <!-- <Dictionary
              :disabled="disabled"
              v-model="form.projectSource"
              code="PROJECT_SOURCE"
              placeholder="请选择项目来源"
            /> -->
            <el-form-item prop="projectSource" ref="projectSource" style="width: 100%">
              <el-radio-group v-model="form.projectSource">
                <div class="radio-group">
                  <div class="radio-label">生产类型:</div>
                  <div class="radio-item">
                    <el-radio
                      :disabled="disabled"
                      v-for="item in dictData.produceList"
                      :key="item.id"
                      :label="item.dictVal"
                      >{{ item.dictName }}</el-radio
                    >
                  </div>
                </div>
                <div class="radio-group">
                  <div class="radio-label">非生产类型:</div>
                  <div class="radio-item">
                    <el-radio
                      :disabled="disabled"
                      v-for="item in dictData.unProduceList"
                      :key="item.id"
                      :label="item.dictVal"
                      >{{ item.dictName }}</el-radio
                    >
                  </div>
                </div>
              </el-radio-group></el-form-item
            >
          </el-descriptions-item>
          <el-descriptions-item label="合作费比例(%)" v-if="form.projectSource == 'zi_zhi_he_zuo'">
            <el-input-number
              :precision="2"
              :step="0.1"
              :max="100"
              :disabled="disabled"
              v-model="form.collaborationCostsRatio"
            ></el-input-number>
          </el-descriptions-item>
          <!-- <el-descriptions-item label="项目来源详细">
            <Dictionary
              :disabled="disabled"
              v-model="form.projectSourceDetailed"
              :code="form.projectSource"
              placeholder="请选择项目详情"
            />
          </el-descriptions-item> -->
          <el-descriptions-item label="外部合作者" :span="2">
            <template slot="label">
              <span style="color: red">{{ options.list_check ? '*' : '' }}</span> 外部合作者
            </template>
            <el-form-item prop="externalPartnere" ref="externalPartnere" style="width: 100%">
              <el-radio
                :disabled="disabled"
                @input="externalPartnereFn"
                v-model="form.externalPartnere"
                label="NOT"
                >无外部合作者</el-radio
              >
              <el-radio
                :disabled="disabled"
                @input="externalPartnereFn"
                v-model="form.externalPartnere"
                label="HAVE"
                >有外部合作者</el-radio
              ></el-form-item
            >
          </el-descriptions-item>
        </el-descriptions>

        <!-- <el-descriptions
          style="margin-top:8px;"
          title="挂靠合作费用(元)"
          direction="vertical"
          :column="1"
          v-if="form.projectSource == 'di_san_fang_gong_si_gua_kao_hui_tu_de_xiang_mu'"
          border
        >
          <el-descriptions-item labelStyle="margin-bottom:0px;display:none">
            <el-input
              :disabled="disabled"
              @input="inputFn('collaborationCosts')"
              @focus="focusFn('collaborationCosts')"
              @blur="blurFn('collaborationCosts')"
              v-model="form.collaborationCosts"
            >
            </el-input>
          </el-descriptions-item>
        </el-descriptions> -->

        <!-- v-if="form.isContentCooperation" -->
        <el-descriptions
          v-if="form.externalPartnere == 'HAVE'"
          style="margin-top: 10px"
          title="外部协作信息"
          direction="vertical"
          :column="2"
          border
        >
          <el-descriptions-item label="费用结算方式">
            <template slot="label">
              <span style="color: red">{{ options.list_check ? '*' : '' }}</span> 费用结算方式
            </template>
            <el-form-item
              prop="contentCooperationType"
              ref="contentCooperationType"
              style="width: 100%"
            >
              <el-select
                style="width: 100%"
                :disabled="disabled"
                v-model="form.contentCooperationType"
                placeholder="请选择"
              >
                <!-- <el-option label="无外部协助" :value="0"> </el-option> -->
                <el-option label="按具体金额" :value="1"> </el-option>
                <el-option label="按比例" :value="2"> </el-option> </el-select
            ></el-form-item>
          </el-descriptions-item>
          <el-descriptions-item
            label="预计外部协作资金金额(元)"
            v-if="form.contentCooperationType == 1"
          >
            <template slot="label">
              <span style="color: red">{{ options.list_check ? '*' : '' }}</span>
              预计外部协作资金金额(元)
            </template>
            <el-form-item
              prop="contentCooperationMoney"
              ref="contentCooperationMoney"
              style="width: 100%"
            >
              <el-input
                v-model="form.contentCooperationMoney"
                placeholder="请输入"
                @input="inputFn('contentCooperationMoney')"
                @focus="focusFn('contentCooperationMoney')"
                @blur="blurFn('contentCooperationMoney')"
                :disabled="disabled"
              ></el-input
            ></el-form-item>
          </el-descriptions-item>
          <el-descriptions-item
            label="预计外部协作资金比例(%)"
            v-if="form.contentCooperationType == 2"
          >
            <template slot="label">
              <span style="color: red">{{ options.list_check ? '*' : '' }}</span>
              预计外部协作资金比例(%)
            </template>
            <el-form-item
              prop="contentCooperationRatio"
              ref="contentCooperationRatio"
              style="width: 100%"
            >
              <el-input
                v-model="form.contentCooperationRatio"
                placeholder="请输入"
                :disabled="disabled"
                oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1');if(value>100)value=100;if(value<0)value=null"
              >
              </el-input
            ></el-form-item>
          </el-descriptions-item>
          <el-descriptions-item label="外部协作内容" :span="2">
            <template slot="label">
              <span style="color: red">{{ options.list_check ? '*' : '' }}</span> 外部协作内容
            </template>
            <el-form-item
              prop="contentCooperationContent"
              ref="contentCooperationContent"
              style="width: 100%"
            >
              <el-input
                type="textarea"
                autosize
                v-model="form.contentCooperationContent"
                placeholder="请输入"
                :disabled="disabled"
              ></el-input
            ></el-form-item>
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions
          style="margin-top: 10px"
          title="角色分配"
          direction="vertical"
          :column="3"
          border
        >
          <el-descriptions-item label="建议商务负责人" v-if="isShowSuggest">
            <el-form-item label-width="0px" style="width: 100%">
              <el-input disabled v-model="form.suggestionPrincipalUserName"> </el-input>
            </el-form-item>
          </el-descriptions-item>
          <!-- 是否名义负责人 -->
          <el-descriptions-item label="是否名义负责人">
            <el-form-item label-width="0px" style="width: 100%">
              <el-radio-group
                v-model="form.isNominalPrincipalUser"
                @change="changeIsNominalPrincipalUser"
              >
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <span style="color: red">*</span>
              {{ form.isNominalPrincipalUser ? '名义商务负责人' : '实际商务负责人' }}
            </template>
            <el-form-item
              label-width="0px"
              :style="isShowSuggest ? 'width:100%' : 'width:240px'"
              prop="principalUserName"
              ref="principalUserName"
            >
              <SelectDialog @selectDataChange="principalUserFn">
                <el-input
                  slot="button"
                  :readonly="true"
                  style="cursor: pointer"
                  :disabled="disabled"
                  v-model="form.principalUserName"
                  :placeholder="
                    form.isNominalPrincipalUser ? '请选择名义商务负责人' : '请选择实际商务负责人'
                  "
                ></el-input>
              </SelectDialog>
            </el-form-item>
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions
          style="margin-top: 10px"
          title="标的认领"
          direction="vertical"
          :column="4"
          border
        >
          <el-descriptions-item :span="4" label="标的名称">
            <template slot="label">
              标的名称
              <!-- (合计： {{ form.bidWinningAmount }} 元) -->
            </template>
            <el-form-item label-width="0px" style="width: 100%">
              <!-- <label @click="addBid" class="choiceDataBtn">
                <el-input
                  :readonly="true"
                  style="cursor: pointer"
                  :disabled="disabled"
                  v-model="bidInfo.bidName"
                  placeholder="请选择投标信息"
                ></el-input>
              </label> -->
              <span v-if="disabled">{{ bidInfo.bidName || '' }}</span>
              <ChoiceBid
                v-else
                ref="choiceBid"
                :bidInfo="bidSelectInfo"
                :bidSelectId="form.bidId"
                :choiceBids="choiceBids"
                :bidIdList="form.bidIdList"
                @choiceDataChange="onBidChoice"
              >
                <div slot="button" class="choice-button">
                  <el-input
                    :readonly="true"
                    style="cursor: pointer; margin-bottom: 8px; margin-right: 20px"
                    v-model="form.bidListStr"
                    placeholder="请选择投标信息"
                  ></el-input>
                  <!-- <el-button
                    type="primary"
                    size="mini"
                    @click.stop="$refs.selectedBid.openDialog()"
                    v-show="form.bidIdList?.length > 0"
                    style="margin-bottom: 8px; width: 70px"
                    >详情</el-button> -->
                  <el-button
                    type="danger"
                    size="mini"
                    @click.stop="clearBid"
                    v-show="form.bidIdList?.length > 0"
                    style="margin-bottom: 8px; width: 70px"
                    >删除</el-button
                  >
                </div>
                <template slot="tableColumn-left">
                  <el-table-column align="center" type="index" label="序号"></el-table-column>
                  <el-table-column
                    align="center"
                    prop="bidName"
                    min-width="280"
                    :show-overflow-tooltip="false"
                    label="标的名称"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="bidType"
                    width="100"
                    :show-overflow-tooltip="false"
                    label="采购方式"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.bidType | dict(dictData.bidType) }}
                    </template>
                  </el-table-column>

                  <el-table-column
                    align="center"
                    width="70"
                    :show-overflow-tooltip="false"
                    prop="bidHostEscort"
                    label="是否重点关注"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.bidHostEscort == 'IS_FLAG' ? '是' : '否' }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    width="180"
                    sortable
                    prop="bidOpeningTime"
                    label="开标时间"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.bidOpeningTime | timeFormat }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    :show-overflow-tooltip="false"
                    min-width="220"
                    prop="bidOwnerUnit"
                    label="业主单位"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    :show-overflow-tooltip="false"
                    min-width="220"
                    prop="bidOwnerUnit"
                    label="代理\招标单位"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    width="160"
                    :show-overflow-tooltip="false"
                    prop="bidWinningAmount"
                    label="中标金额(元)"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.bidWinningAmount | applyAmount }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    min-width="160"
                    :show-overflow-tooltip="false"
                    prop="bidRemark"
                    label="备注"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="bidStatus"
                    label="状态"
                    width="160"
                    :show-overflow-tooltip="false"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.bidStatus | dict(dictData.bidStatus) }}
                    </template>
                  </el-table-column>
                </template>
              </ChoiceBid>
            </el-form-item>
          </el-descriptions-item>
          <!-- <el-descriptions-item label="中标金额合计">
            <div>中标金额合计: {{ form.bidWinningAmount }} （元）</div>
          </el-descriptions-item> -->
        </el-descriptions>
        <!-- <div>中标金额合计: {{ form.bidWinningAmount }} （元）</div> -->
        <div class="bidInfo">
          <template v-for="(bidInfo, index) in choiceBids">
            <el-divider content-position="left">
              <span style="font-size: 16px">{{ bidInfo.bidName }}</span>
            </el-divider>
            <!-- <span style="font-size: 16px;">{{ bidInfo.bidName }}</span> -->
            <el-descriptions direction="vertical" :column="6" border>
              <el-descriptions-item label="采购方式">
                {{ bidInfo.bidType | dict(dictData.bidType) }}
              </el-descriptions-item>
              <el-descriptions-item label="是否重点关注">
                {{ bidInfo.bidHostEscort == 'IS_FLAG' ? '是' : '否' }}
              </el-descriptions-item>
              <el-descriptions-item label="开标时间" :span="2">
                {{ bidInfo.bidOpeningTime | timeFormat }}
              </el-descriptions-item>
              <el-descriptions-item label="业主单位" :span="2">
                {{ bidInfo.bidOwnerUnit }}
              </el-descriptions-item>
              <el-descriptions-item label="代理\招标单位" :span="2">
                {{ bidInfo.bidAgency }}
              </el-descriptions-item>
              <el-descriptions-item label="中标金额(元)" :span="2">
                {{ bidInfo.bidWinningAmount | applyAmount }}
              </el-descriptions-item>
              <el-descriptions-item label="状态">
                {{ bidInfo.bidStatus | dict(dictData.bidStatus) }}
              </el-descriptions-item>
            </el-descriptions>
          </template>
        </div>
      </div>
    </el-form>
    <div v-show="opinionRecordList.length > 0">
      <h3 v-if="form.id" style="margin-top: 20px">意见区：</h3>
      <div class="text-center">
        <template>
          <div class="text-center-top" v-for="i in opinionRecordList" :key="i.id">
            <span class="text-center-top-left">
              <el-button class="text-btn" type="success" disabled v-if="i.isCheckPass === '1'"
                >通过</el-button
              >
              <el-button type="info" class="text-btn" disabled v-else-if="i.isCheckPass === '2'"
                >不通过</el-button
              >
              <el-button type="danger" class="text-btn" disabled v-else-if="i.isCheckPass === '5'"
                >商务终结</el-button
              >
              <span class="opinion">审批意见：{{ i.opinion }}</span>
            </span>
            <span class="text-center-top-right">
              <span class="name">处理人：{{ i.auditUserName }}</span>
              <span class="day">审批时间：{{ i.auditDate | timeFormat }}</span>
            </span>
          </div>
        </template>
      </div>
    </div>

    <el-dialog width="50%" title="项目来源说明" append-to-body :visible.sync="projectSourceIsShow">
      <div class="imgDialog">
        <img :src="`${imgProjectSource}?${new Date().getTime()}`" alt="项目来源说明" width="100%" />
      </div>
    </el-dialog>

    <el-dialog
      width="50%"
      title="业务种类说明"
      append-to-body
      :visible.sync="businessLinesIsShow"
      class="businessLinesDialog"
    >
      <el-table
        :data="businessLinesTableData"
        style="width: 100%"
        border
        height="550px"
        :cell-class-name="clearColumnPadding"
        size="small"
      >
        <el-table-column
          prop="dictName"
          label="业务种类"
          align="center"
          width="350px"
          class-name="height-light"
        >
        </el-table-column>
        <el-table-column label="项目种类" :show-overflow-tooltip="false">
          <template slot-scope="scope">
            <el-table :data="scope.row.dictDataTwoList" style="width: 100%" :show-header="false">
              <el-table-column prop="dictName" align="center" class-name="content-text">
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      width="60%"
      title="增值税类型说明"
      append-to-body
      :visible.sync="vatTypesShow"
      class="vatDialog"
    >
      <el-table
        :data="VatTypesTableData"
        style="width: 100%"
        row-key="id"
        border
        default-expand-all
        height="550px"
        :tree-props="{ children: 'dictDataTwoList' }"
        :cell-class-name="vatTypeTableCellStyle"
        :span-method="arraySpanMethod"
      >
        <el-table-column prop="vatName" label="内容"> </el-table-column>
        <el-table-column label="税率" :show-overflow-tooltip="false" width="100px">
          <template slot-scope="scope">
            <el-table
              v-if="scope.row.dictDataThreeList && scope.row.dictDataThreeList.length !== 0"
              :data="scope.row.dictDataThreeList"
              style="width: 100%; height: 100%"
              :show-header="false"
              cell-class-name="cellContent"
              row-class-name="clear-right-border"
            >
              <el-table-column prop="vatRate" width="100px" :show-overflow-tooltip="false">
                <template slot-scope="scope">
                  {{ scope.row.vatRate + '%' }}
                </template>
              </el-table-column>
              <el-table-column prop="remark" :show-overflow-tooltip="false">
                <template slot-scope="scope">
                  <div class="remark-box">
                    {{ scope.row.remark }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div v-else></div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      width="40%"
      title="商务税费类型说明"
      append-to-body
      :visible.sync="taxTypesShow"
      class="vatDialog"
    >
      <el-table
        :data="taxTypesTableData"
        style="width: 100%"
        border
        height="300px"
        :cell-class-name="vatTypeTableCellStyle"
        :span-method="texTableCellMerge"
      >
        <el-table-column prop="taxType" label="税率类型"> </el-table-column>
        <el-table-column prop="projectNature" label="项目性质"></el-table-column>
        <el-table-column prop="projectSource" label="项目来源"> </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 商务操作记录 -->
    <BusinessLog :isShow.sync="businessIsShow" :options="form" />
    <SelectedBid ref="selectedBid" :choiceBids="choiceBids" :dictData="dictData"></SelectedBid>
  </div>
</template>
<script>
import { numberToCurrencyNo, delcommafy, changeMoneyToChinese } from '@/util/jsencrypt'
import { phone2 } from '@/util/regular'
import { mapState, mapGetters } from 'vuex'

import { formLocationError } from '@/util/common'

export default {
  components: {
    Dictionary: () => import('@/components/Dictionary.vue'),
    VatDictionary: () => import('@/components/VatDictionary.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    SelectDialog: () => import('@/components/selectDialog.vue'),
    BusinessLog: () => import('@/components/business/business-log.vue'),
    ChoiceBid: () => import('@/components/business/ChoiceBid.vue'),
    SelectedBid: () => import('./SelectedBid.vue'),
  },
  props: {
    BusinessId: {},
    isBusinessLog: {
      //操作日志
      type: Boolean,
      default: false,
    },
    isProject: {
      type: Boolean,
      default: false,
    },
    isShowSuggest: {
      //建议/实际商务负责人
      type: Boolean,
      default: false,
    },
    disbursementShow: {
      //更改后审批提示
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  watch: {
    choiceBids: {
      immediate: true,
      handler: function (a) {
        if (a.length) {
          this.form.bidWinningAmount = a.reduce((a, b) => {
            return a + b.bidWinningAmount
          }, 0)
        }
      },
    },
    BusinessId: {
      immediate: true,
      handler: function (a) {
        if (a) {
          // this.getData(a)
          this.init(a)
          // this.getUser()
        }
      },
    },
    options: {
      deep: true,
      immediate: true,
      handler: function (options) {
        if (options.list_s == 1) {
          this.disabled = false
        } else if (options.list_s == 2) {
          this.disabled = true
        }
      },
    },
    'form.contentCooperationType': {
      handler: function (val) {
        if (val == 0) {
          this.form.contentCooperationMoney = null
          this.form.contentCooperationRatio = null
        } else if (val == 1) {
          this.form.contentCooperationRatio = null
        } else if (val == 2) {
          this.form.contentCooperationMoney = null
        }
      },
    },
  },
  data() {
    // 商务名称自定义校验规则
    var validateBusiness = (rule, value, callback) => {
      if (!this.disabled) {
        if (value == '') {
          callback(new Error('请输入商务名称'))
        } else {
          if (value === '前期面上沟通') {
            callback(new Error('商务名称不能为前期面上沟通，请重新输入'))
          } else {
            let flag = this.businessNameList.some(item => item.value == value)
            if (flag && value !== this.selectBusinessName) {
              // 说明有重复的商务名称，回显校验提示内容
              callback(new Error('当前商务名称与历史商务重复，请注意修改'))
            } else {
              callback()
            }
          }
        }
      } else {
        callback()
      }
    }
    return {
      taxTypesShow: false,
      showEditDialog: false,
      editDialogLoading: true,
      loading: false,
      submitLoading: false,
      form: {},
      activeName: '0',
      rules: {
        businessName: [{ validator: validateBusiness, trigger: 'change' }],
        ownerUnit: [
          {
            required: true,
            message: '请输入业主单位',
            trigger: ['blur', 'change'],
          },
        ],
        contactsPhone: [
          {
            pattern: phone2,
            message: '请输入正确的联系电话',
            trigger: 'blur',
            required: true,
          },
        ],
        // decisionMakerContactsPhone: [
        //   {
        //     pattern: phone2,
        //     message: '请输入正确的联系电话',
        //     trigger: 'blur',
        //     required: true,
        //   },
        // ],
        businessType: [
          {
            required: true,
            message: '请选择商务类型',
            trigger: ['blur', 'change'],
          },
        ],
        disbursementType: [
          {
            required: true,
            message: '请选择商务支出类型',
            trigger: ['blur', 'change'],
          },
        ],
        principalUserName: [
          {
            required: true,
            message: '请选择实际商务负责人',
            trigger: ['blur', 'change'],
          },
        ],
        //
        provideId: [
          {
            required: true,
            message: '请选择信息提供人',
            trigger: 'blur',
          },
        ],
        companyType: [
          {
            required: true,
            message: '请选择项目承接公司',
            trigger: ['blur', 'change'],
          },
        ],
        contacts: [
          {
            required: true,
            message: '请输入经办人',
            trigger: 'blur',
          },
        ],
        // post: [
        //   {
        //     required: true,
        //     message: '请输入经办人职务',
        //     trigger: 'blur',
        //   },
        // ],
        // decisionMaker: [
        //   {
        //     required: true,
        //     message: '请输入决策人',
        //     trigger: 'blur',
        //   },
        // ],
        // decisionMakerPost: [
        //   {
        //     required: true,
        //     message: '请输入决策人职务',
        //     trigger: 'blur',
        //   },
        // ],
        // bidWay: [
        //   {
        //     required: true,
        //     message: '请选择招标方式',
        //     trigger: ['blur', 'change'],
        //   },
        // ],
        // tenderFee: [
        //   {
        //     required: true,
        //     message: '请输入招投标费用',
        //     trigger: 'blur',
        //   },
        // ],
        ourContractAmount: [
          {
            required: true,
            message: '请输入我方合同金额',
            trigger: 'blur',
          },
        ],
        content: [
          {
            required: true,
            message: '请输入外部合作者信息的商务说明',
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (this.form.externalPartnere === 'HAVE') {
                if (!value) {
                  callback(new Error('请输入外部合作者信息的商务说明'))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            },
          },
        ],
        taxType: [
          {
            required: true,
            message: '请选择税费类型',
            trigger: ['blur', 'change'],
          },
        ],
        vatType: [
          {
            required: true,
            message: '请选择增值税类型',
            trigger: ['blur', 'change'],
            validator: (rule, value, callback) => {
              if (!this.form.taxType === 'THIRD_PARTY_TAX') {
                if (!value) {
                  callback(new Error('请选择增值税类型'))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            },
          },
        ],
        vatData: [
          {
            required: true,
            message: '请选择增值税类型详细',
            trigger: ['blur', 'change'],
            validator: (rule, value, callback) => {
              if (!this.form.taxType === 'THIRD_PARTY_TAX') {
                if (!value) {
                  callback(new Error('请选择增值税类型详细'))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            },
          },
        ],
        businessLines: [
          {
            required: true,
            message: '请选择业务种类',
            trigger: ['blur', 'change'],
          },
        ],
        projectSource: [
          {
            required: true,
            message: '请选择项目来源',
            trigger: ['blur', 'change'],
          },
        ],
        externalPartnere: [
          {
            required: true,
            message: '请选择外部合作者',
            trigger: ['blur', 'change'],
          },
        ],
        contentCooperationType: [
          {
            required: true,
            message: '请选择费用结算方式',
            trigger: ['blur', 'change'],
          },
        ],
        contentCooperationMoney: [
          {
            required: true,
            message: '请输入外部协助费用金额',
            trigger: 'blur',
          },
        ],
        contentCooperationRatio: [
          {
            required: true,
            message: '请输入外部协助费用比例',
            trigger: 'blur',
          },
        ],
        contentCooperationContent: [
          {
            required: true,
            message: '请输入外部协作内容',
            trigger: 'blur',
          },
        ],
        businessStartDate: [
          {
            required: true,
            message: '选择商务实际开始时间',
            trigger: ['blur', 'change'],
          },
        ],
      },
      deptList: [], // 部门列表
      postList: [], //岗位列表
      type: 'portrait',
      file_Data: [],
      dialogFormVisible: false,
      dg_form: {},
      Dispose_show: false,
      Dispose_data: {},
      showzhipai: false,
      zzsList: [],
      user_Data: [],
      user_show: false,
      UserData: [],
      deptData: [],
      disabled: true,
      linshi_show: false,
      linshi_Name: '',
      isCcheck: false,
      isEditCheck: false, //全部商务编辑校验
      BusinessLeader_Show: false, //商务牵头人-商务牵头参与者
      BusinessLeader_Name: '',
      BusinessManager_Show: false, //商务经理
      BusinessManager_Name: '',
      BusinessLeadParticipant_Name: '',
      BusinessDeveloper_Show: false, //商务开展人
      BusinessDeveloper_Name: '',
      AssignedBy_Show: false, //指派人
      AssignedByr_Name: '',
      projectSourceIsShow: false,
      businessLinesIsShow: false,

      companyList: [], //业主单位数组
      businessNameList: [], //商务名称数组
      selectBusinessName: '',
      dictData: {
        bidwayOptions: [],
        projectSource: [],
        produceList: [],
        unProduceList: [],
        businessLines: [],
        bidType: [],
        bidStatus: [],
      },
      imgUrl: 'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/业务种类.png',
      imgProjectSource: 'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/生产费用.png',
      opinionRecordList: [],
      temporaryVatRate: '',
      vatTypesShow: false,
      imgVatTypes: 'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/税率表.png',

      businessLinesTableData: [], //业务种类弹框数据
      VatTypesTableData: [], //增值税弹框数据
      colFields: ['taxType', 'projectNature', 'projectSource'], // 存放所有的表头 一定要与taxTypesTableData一致
      // 存储税率类型说明
      taxTypesTableData: [
        {
          taxType: '我方支付所有税费',
          projectNature: '生产项目',
          projectSource: '一手直签',
        },
        {
          taxType: '我方支付所有税费',
          projectNature: '生产项目',
          projectSource: '资质合作',
        },
        {
          taxType: '我方支付所有税费',
          projectNature: '生产项目',
          projectSource: '分包转包',
        },
        {
          taxType: '各付各税',
          projectNature: '合作项目',
          projectSource: '资质提供',
        },
        {
          taxType: '各付各税',
          projectNature: '合作项目',
          projectSource: '整体外协',
        },
        {
          taxType: '各付各税',
          projectNature: '合作项目',
          projectSource: '商务居间',
        },
        {
          taxType: '无税',
          projectNature: '零星项目',
          projectSource: '不签合同不开票不开收据的零星项目',
        },
      ], // 税率类型弹框数据
      isBusinessNameTooltip: false,
      oldBusinessForm: {}, //记录原始数据
      businessIsShow: false, //商务操作记录

      bidInfo: {
        bidName: '',
      },
      bidSelectInfo: {},
      spanArr: [], // 存储计算合并的行数
      choiceBids: [],
    }
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return 0
      }
    },
    timeFormat(value, fmt) {
      if (value) {
        value = Number(value)
        return new Date(value).format('yyyy-MM-dd hh:mm:ss')
      } else {
        return ''
      }
    },
  },
  computed: {
    ...mapState({
      businessInformation: state => state.business.businessInformation,
    }),
    taxesFee() {
      //税费
      let num = 0
      let j_configcomp = this.j_configcomp ? this.j_configcomp : 0
      let j_actualStampTax = this.j_actualStampTax ? this.j_actualStampTax : 0
      let j_actualAdditionalTax = this.j_actualAdditionalTax ? this.j_actualAdditionalTax : 0
      num = Number(j_configcomp) + Number(j_actualStampTax) + Number(j_actualAdditionalTax)
      this.form.taxesFee = num.toFixed(2)
      return num.toFixed(2)
    },
    j_actualAdditionalTax() {
      //附加税
      let num = 0
      let j_configcomp = this.j_configcomp ? this.j_configcomp : 0
      num = (j_configcomp * 0.12).toFixed(2)
      this.form.additionalTaxRate = 12 //附加税率
      this.form.actualAdditionalTax = num
      return num
    },
    j_actualStampTax() {
      //印花税
      let ourContractAmount = Number(delcommafy(this.form.ourContractAmount))
      let num = 0
      if (this.form.taxType === 'SPLIT_TAX') {
        ourContractAmount -= this.calculateCooperationMoney(ourContractAmount)
        num = (ourContractAmount * 0.0003).toFixed(2)
      } else if (this.form.taxType === 'SELF_PAY_TAX') {
        // 直接计算
        num = (ourContractAmount * 0.0003).toFixed(2)
      } else if (this.form.taxType === 'THIRD_PARTY_TAX') {
        num = 0
      }
      this.form.stampTaxRate = 0.03 //印花税率
      this.form.actualStampTax = num
      return num
    },
    j_configcomp() {
      //增值税
      let ourContractAmount = Number(delcommafy(this.form.ourContractAmount))
      let num = 0
      if (this.form.taxType === 'SPLIT_TAX') {
        if (this.form.vatType) {
          ourContractAmount -= this.calculateCooperationMoney(ourContractAmount)
        }
        num = this.calculateVat(ourContractAmount)
      } else if (this.form.taxType === 'SELF_PAY_TAX' && this.form.vatType) {
        // 直接计算
        num = this.calculateVat(ourContractAmount)
      } else if (this.form.taxType === 'THIRD_PARTY_TAX') {
        num = 0
      }
      this.form.actualVat = num
      return num
    },
    tenderFeeChinese() {
      if (this.form.tenderFee) {
        const numMoney = delcommafy(this.form.tenderFee)
        return changeMoneyToChinese(numMoney)
      } else {
        return '零圆整'
      }
    },
    ourContractAmountChinese() {
      if (this.form.ourContractAmount) {
        const numMoney = delcommafy(this.form.ourContractAmount)
        return changeMoneyToChinese(numMoney)
      } else {
        return '零圆整'
      }
    },
  },
  created() {
    this.listConfig()
    this.getPartnerCompany()
    this.getBusinessNameList()
    this.$api.dict
      .listSysDictData('BUSINESS_TOUBIAO', true)
      .then(res => {
        this.dictData.bidwayOptions = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BID_TYPE', true)
      .then(res => {
        this.dictData.bidType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BID_STATUS', true)
      .then(res => {
        this.dictData.bidStatus = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('PROJECT_SOURCE', true)
      .then(res => {
        this.dictData.projectSource = res.data
        const produceList = []
        const unProduceList = []
        this.dictData.projectSource.forEach(item => {
          switch (item.dictVal) {
            case 'yi_shou_zhi_qian':
              produceList.push(item)
              break
            case 'zi_zhi_he_zuo':
              produceList.push(item)
              break
            case 'fen_bao_zhuan_bao':
              produceList.push(item)
              break
            case 'zi_zhi_ti_gong':
              unProduceList.push(item)
              break
            case 'zheng_ti_wai_xie':
              unProduceList.push(item)
              break
            case 'shang_wu_ju_jian':
              unProduceList.push(item)
              break
          }
        })
        this.dictData.produceList = produceList
        this.dictData.unProduceList = unProduceList
      })
      .catch(err => {
        console.log(err)
      })
  },
  methods: {
    changeIsNominalPrincipalUser() {
      if (this.form?.isNominalPrincipalUser) {
        this.rules.principalUserName = [
          {
            required: true,
            message: '请选择名义商务负责人',
            trigger: ['blur', 'change'],
          },
        ]
      } else {
        this.rules.principalUserName = [
          {
            required: true,
            message: '请选择实际商务负责人',
            trigger: ['blur', 'change'],
          },
        ]
      }
    },
    showTaxInfo() {
      this.getSpanArr(this.taxTypesTableData)
      this.taxTypesShow = true
    },
    /**
     * 分析每一列，找出相同的
     * @param data
     */
    getSpanArr(data) {
      for (let i = 0; i < this.taxTypesTableData.length; i++) {
        let row = i
        // let col = i % this.colCount;
        if (row === 0) {
          // i 表示行 j表示列
          for (let j = 0; j < this.colFields.length; j++) {
            this.spanArr[i * this.colFields.length + j] = {
              rowspan: 1,
              colspan: 1,
            }
          }
        } else {
          for (let j = 0; j < this.colFields.length; j++) {
            // 当前和上一次的一样
            // 1. 合并所有列的相同数据单元格
            if (
              this.taxTypesTableData[row][this.colFields[j]] ===
              this.taxTypesTableData[row - 1][this.colFields[j]]
            ) {
              let beforeItem = this.spanArr[(row - 1) * this.colFields.length + j]
              this.spanArr[row * this.colFields.length + j] = {
                rowspan: 1 + beforeItem.rowspan, // 合并几行
                colspan: 1, // 合并几列,我这里只是跨行合并,不跨列合并,所以用的1
              }
              beforeItem.rowspan = 0
              beforeItem.colspan = 0
            } else {
              // rowspan 和 colspan 都为1表格此单元格不合并
              this.spanArr[row * this.colFields.length + j] = {
                rowspan: 1,
                colspan: 1,
              }
            }
          }
        }
      }
      // 对数据进行倒序
      let stack = []
      for (let i = 0; i < this.colFields.length; i++) {
        for (let j = 0; j < this.taxTypesTableData.length; j++) {
          // console.log("i=" + i + " j=" + j);
          // i 表示列 j表示行
          if (j === 0) {
            if (this.spanArr[j * this.colFields.length + i].rowspan === 0) {
              stack.push(this.spanArr[j * this.colFields.length + i])
            }
          } else {
            if (this.spanArr[j * this.colFields.length + i].rowspan === 0) {
              stack.push(this.spanArr[j * this.colFields.length + i])
            } else {
              stack.push(this.spanArr[j * this.colFields.length + i])
              while (stack.length > 0) {
                let pop = stack.pop()
                let len = stack.length
                this.spanArr[(j - len) * this.colFields.length + i] = pop
              }
            }
          }
        }
      }
    },
    texTableCellMerge({ row, column, rowIndex, columnIndex }) {
      return this.spanArr[rowIndex * this.colFields.length + columnIndex]
    },
    // 获取外部协作费用
    calculateCooperationMoney(ourContractAmount) {
      if (this.form.contentCooperationType === 1) {
        return Number(delcommafy(this.form.contentCooperationMoney))
      } else if (this.form.contentCooperationType === 2) {
        return ourContractAmount * (Number(this.form.contentCooperationRatio) / 100)
      }
      return 0
    },
    // 解决浮点数，计算增值税公式
    calculateVat(ourContractAmount) {
      return (
        (((ourContractAmount * 10000) / (1000000 + this.form.vatRate * 10000)) *
          (this.form.vatRate * 10000)) /
        10000
      ).toFixed(2)
    },
    onChangeTaxtype(val) {
      if (val === 'THIRD_PARTY_TAX') {
        this.form.vatType = null
        this.form.vatData = null
        this.form.vatRate = null
      } else if (val === 'SPLIT_TAX') {
        this.form.externalPartnere = 'HAVE'
        // if (this.form.externalPartnere != 'HAVE') {
        //   this.$confirm('此商务是否有外部合作者', '提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning',
        //   })
        //     .then(() => {
        //       this.form.externalPartnere = 'HAVE'
        //     })
        //     .catch(() => {
        //       this.form.taxType = null
        //     })
        // }
      }
    },
    // 前往商务
    async leaveFor() {
      const { href } = this.$router.resolve({
        path: '/businessManage/allManage/Index',
        query: {
          id: JSON.stringify(this.form.id),
        },
      })
      window.open(href, '_blank')
    },
    mouseoverBusinessName() {
      if (this.form.businessName && this.form.businessName.length > 50) {
        this.isBusinessNameTooltip = true
      } else {
        this.isBusinessNameTooltip = false
      }
    },
    mouseoutBusinessName() {
      this.isBusinessNameTooltip = false
    },
    optionsChange(v) {
      if (v[0]) {
        const vatRate = v.find(item => item.vatType == this.temporaryVatRate)
        if (vatRate) {
          this.form.vatRate = this.temporaryVatRate
        } else {
          this.form.vatRate = v[0].vatType
        }
      }
    },
    getPartnerCompany() {
      this.$api.partnerCompany
        .getPartnerCompanyDataListNoPage({})
        .then(res => {
          if (res) {
            this.companyList = res.data.map(item => {
              return {
                value: item.name,
                id: item.id,
                contacts: item.legalPerson,
                post: item.post,
                contactsPhone: item.phone,
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getBusinessNameList() {
      this.$api.businessManage
        .getNameListNoPage({})
        .then(res => {
          if (res) {
            this.businessNameList = res.data.map(item => {
              return { value: item }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 'NOT''HAVE'
    externalPartnereFn(val) {
      if (val == 'NOT') {
        this.form.contentCooperationType = null
        this.form.contentCooperationMoney = null
        this.form.contentCooperationRatio = null
        this.form.contentCooperationContent = null
        // this.$refs.form.clearValidate('content')
        if (this.form.taxType == 'SPLIT_TAX') {
          this.form.taxType = null
        }
      }
    },

    businessCheck() {
      this.$refs.form.validate((valid, object) => {
        if (valid) {
          this.isCcheck = true
        } else {
          if (this.$refs) {
            formLocationError(object, this.$refs)
          }

          this.isCcheck = false
          return false
        }
      })
    },

    // 编辑全部商务校验
    businessEditCheck() {
      let arr = []
      this.$refs.form.validateField(
        ['businessName', 'ownerUnit', 'contacts', 'contactsPhone', 'content', 'businessStartDate'],
        err => {
          if (err) {
            // 说明触发了校验规则，表单不通过
            arr.push(false)
          } else {
            arr.push(true)
          }
        }
      )
      this.isEditCheck = arr.every(item => item === true)
    },
    //   linshiTreeClick(data, checked, node) {
    //   if (checked) {
    //     if (data.id) {
    //       this.$refs.linshiUserDataTree.setCheckedKeys([data.id])
    //       // this.linshi_Name = data.userName;
    //       this.linshi_Name = this.getLinshi_initiator(data.id)
    //       this.form.provideId = data.id
    //     }
    //   }
    // },
    linshiTreeFn(row) {
      this.linshi_Name = this.getLinshi_initiator(row.id)
      this.form.provideId = row?.id
      this.$forceUpdate()
    },
    async init(a) {
      await this.getStaffList(a)
      this.$emit('showEditDialog')
    },
    /** 值改变触发 "申请金额" 文本框 **/
    inputFn(e) {
      this.form[e] = String(this.form[e])
        .replace(/[^\d.]/g, '')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      this.$forceUpdate()
    },

    /** 获得焦点触发 "申请金额" 文本框 **/
    focusFn(e) {
      this.form[e] = this.form[e] ? this.form[e] : 0
      this.form[e] = Number(String(this.form[e]).replace(/,/g, ''))
      this.$forceUpdate()
    },

    /** 失去焦点触发 "申请金额" 文本框 **/
    blurFn(e) {
      this.form[e] = Number(this.form[e]).toLocaleString()
      this.$forceUpdate()
    },
    dataUpdate(row) {
      if (row) {
        this.form.vatData = null
        this.form.vatRate = null
      }
    },
    principalUserFn(row) {
      var arr = row.deptNames.split(',')
      this.form.principalUserName = row.userName + ' - ' + arr[0]
      this.form.principalUserId = row?.id
      this.$forceUpdate()
    },
    getData(BusinessId) {
      this.editDialogLoading = true
      if (BusinessId) {
        this.$api.businessManage
          .getBusinessManage(BusinessId)
          .then(res => {
            if (res.data?.businessManage) {
              this.oldBusinessForm = res.data.businessManage.deepClone()
              this.$store.commit({
                type: 'business/SET_BUSINESS_INFORMATION',
                businessInformation: res.data.businessManage,
              })
              this.$store.commit({
                type: 'business/SET_USER_ASSIGNMENT_MAP',
                userAssignmentMap: res.data.userAssignmentMap,
              })
              this.temporaryVatRate = res.data.businessManage.vatRate
                ? res.data.businessManage.vatRate
                : ''
            }
            this.$store.commit({
              type: 'business/SET_BID_INFO',
              bidManagementResp: res.data.bidManagementResp || {},
            })
            this.$store.commit({
              type: 'business/SET_BUSINESS_PROJECT_LIST',
              businessProjectList: res.data.projectList,
            })
            let arr = []
            if (res.data.businessFileList.length > 0) {
              for (let i = 0; i < res.data.businessFileList.length; i++) {
                const e = res.data.businessFileList[i]
                e.attachmentName = e.fileName
                e.attachmentPath = e.filePath
                arr.push(e)
              }
            }
            this.file_Data = arr
            this.$emit('getBusinessFile', this.file_Data) //全部商务获取附件

            this.form = {
              ...res.data?.businessManage,
              bidId: res.data?.bidManagementResp?.id,
              applicantId: res.data?.businessManage.initiatorId,
              ourContractAmount: numberToCurrencyNo(res.data?.businessManage.ourContractAmount),
              predictMoney: numberToCurrencyNo(res.data?.businessManage.predictMoney),
              contentCooperationMoney: numberToCurrencyNo(
                res.data?.businessManage.contentCooperationMoney
              ),
              bidListStr: res.data?.bidManagementRespList.map(item => item.bidName).join('; '),
              bidIdList: res.data?.bidManagementRespList.map(item => item.id),
            }
            this.choiceBids = res.data?.bidManagementRespList || []
            this.form.tenderFee = this.form.tenderFee ? this.form.tenderFee : 0
            this.form.ourContractAmount = this.form.ourContractAmount
              ? this.form.ourContractAmount
              : 0
            this.form.predictMoney = this.form.predictMoney ? this.form.predictMoney : 0
            this.form.contentCooperationRatio = this.form.contentCooperationRatio
              ? this.form.contentCooperationRatio
              : 0
            this.form.contentCooperationMoney = this.form.contentCooperationMoney
              ? this.form.contentCooperationMoney
              : 0
            this.form.actualStampTax = this.form.actualStampTax ? this.form.actualStampTax : 0
            this.form.actualAdditionalTax = this.form.actualAdditionalTax
              ? this.form.actualAdditionalTax
              : 0
            this.form.taxesFee = this.form.taxesFee ? this.form.taxesFee : 0
            this.bidInfo = res.data.bidManagementResp || {}
            this.bidSelectInfo = res.data.bidManagementResp || {}
            this.opinionRecordList = []
            this.opinionRecordList = res.data.opinionRecordList
            this.selectBusinessName = this.form.businessName
            if (this.user_Data.length > 0) {
              // 定义一个函数来更新表单字段
              const updateFormField = (userId, formFieldName) => {
                const e = this.user_Data.find(u => u.id === userId)
                if (e) {
                  const departmentArr = e.departmentName.split(',')
                  if (formFieldName === 'linshi_Name') {
                    this.linshi_Name = e.userName + '-' + departmentArr[0]
                  } else {
                    this.form[formFieldName] = e.userName + ' - ' + departmentArr[0]
                  }
                }
              }

              // 使用 updateFormField 来更新表单字段
              updateFormField(res.data.businessManage.provideId, 'linshi_Name')
              updateFormField(res.data.businessManage.principalUserId, 'principalUserName')
              updateFormField(
                res.data.businessManage.suggestionPrincipalUserId,
                'suggestionPrincipalUserName'
              )
              updateFormField(res.data.businessManage.promoterId, 'promoterName')
              updateFormField(res.data.businessManage.appointorId, 'appointorName')
              updateFormField(res.data.businessManage.businessManagerId, 'businessManagerName')
              updateFormField(res.data.businessManage.leaderId, 'leaderName')
            }

            this.$nextTick(() => {
              if (this.$refs.form) {
                this.$refs.form.clearValidate()
              }
              this.isCcheck = false
              this.$forceUpdate()
            })
            this.editDialogLoading = false
          })
          .catch(err => {
            this.editDialogLoading = false
            console.log(err)
          })
      } else {
        this.file_Data = []
      }
    },
    listConfig() {
      this.$api.vat
        .getVatTypeList()
        .then(res => {
          this.zzsList = res?.data?.records
        })
        .catch(err => {
          console.log(err)
        })
    },
    async getStaffList(a) {
      const res = await this.$api.user.staffAllList({})
      this.user_Data = res.data
      this.$store.commit({
        type: 'business/SET_COMPANY_MEBERS',
        CompanyMembers: res.data,
      })

      await this.getData(a)
    },
    getLinshi_initiator(v) {
      let i = ''
      this.user_Data.forEach(e => {
        if (e.id == v) {
          var arr = e.departmentName.split(',')
          i = e.userName + ' - ' + arr[0]
        }
      })
      return i
    },

    // 可筛选输入框回调
    querySearch(queryString, cb) {
      let companyList = this.companyList
      let results = queryString ? this.createFilter(queryString) : companyList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },

    createFilter(queryString) {
      let results = this.companyList
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(companyItem => companyItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },

    onInputSelect(e) {
      if (e.value) {
        this.form.ownerUnit = e.value
        this.form.contactsPhone = e.contactsPhone
        this.form.contacts = e.contacts
        this.form.post = e.post
        const arr = ['ownerUnit', 'contactsPhone', 'contacts', 'post']
        arr.forEach(ref => {
          if (this.$refs[ref]) {
            this.$refs[ref].clearValidate()
          }
        })
      }
      this.$forceUpdate()
    },

    businessQuerySearch(queryString, cb) {
      let businessNameList = this.businessNameList
      let results = queryString ? this.businessNameCreateFilter(queryString) : businessNameList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },

    businessNameCreateFilter(queryString) {
      let results = this.businessNameList
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },

    onBusinessNameSelect(e) {
      if (e) {
        this.form.businessName = e.value
        // this.$refs.businessName.clearValidate()
        this.$refs.form.validateField('businessName')
      }
      this.$forceUpdate()
    },

    clearColumnPadding({ columnIndex }) {
      if (columnIndex == 1) {
        return 'cellName clearBorder'
      }
    },

    // 显示业务类型弹框
    showBusinessLinesIsShow() {
      this.$api.dict
        .getTreeDataList({ dictType: 'BUSINESS_LINES' })
        .then(res => {
          this.businessLinesTableData = res.data
          this.businessLinesIsShow = true
        })
        .catch(err => {
          console.log(err)
        })
    },

    vatTypeTableCellStyle({ row, columnIndex }) {
      let styleArr = ['cellName']
      if (row.dictDataThreeList) {
        styleArr.push('cellContent')
        if (row.hasChildren && columnIndex == 1) {
          styleArr.push('clearBorder')
        }
        return styleArr.join(' ')
      } else {
        styleArr.push('cellTitle')
        if (columnIndex == 0) {
          styleArr.push('cellTitleFont')
        } else if (columnIndex == 2) {
          styleArr.push('cellTitleAlign')
        }
        return styleArr.join(' ')
      }
    },

    // 显示增值税弹框
    showVatTypesShow() {
      this.$api.vat
        .getTreeDataList()
        .then(res => {
          this.VatTypesTableData = res.data
          this.VatTypesTableData.forEach((item, index) => {
            item.id = index + ''
            if (item.dictDataTwoList) {
              item.dictDataTwoList.forEach((item2, index2) => {
                if (item2.dictDataThreeList && item2.dictDataThreeList.length !== 0) {
                  item2.hasChildren = true
                }
                item2.id = String(index) + String(index2)
              })
            }
          })
          console.log(this.VatTypesTableData)
          this.vatTypesShow = true
        })
        .catch(err => {
          console.log(err)
        })
    },

    arraySpanMethod({ row, columnIndex }) {
      if (row.hasChildren) {
        if (columnIndex == 1) {
          return [1, 2]
        }
      }
    },

    onBidChoice(row, del) {
      if (del) {
        this.choiceBids = this.choiceBids.filter(item => item.id !== row.id)
        this.form.bidIdList = this.form.bidIdList.filter(item => item != row.id)
      } else {
        this.choiceBids.push(row)
        this.form.bidIdList.push(row.id)
      }

      // this.form.bidWinningAmount = this.choiceBids.reduce((pre, cur) => {
      //   return pre + cur.bidWinningAmount
      // }, 0)
      this.form.bidId = row.id
      this.form.bidListStr = this.choiceBids.map(item => item.bidName).join('; ')
      this.bidInfo = { ...row }
    },
    clearBid() {
      this.form.bidIdList = []
      this.choiceBids = []
      this.form.bidListStr = ''
    },
  },
}
</script>

<style scoped lang="scss">
// @import "@/styles/edit.scss";

@import '@/styles/config.scss';
.danwei {
  position: absolute;
  right: 2%;
}

.text-center-btm {
  margin-bottom: 10px;
  span {
    margin-right: 5px;
  }
  padding-top: 10px;
  .el-input {
    margin-left: 8px;
  }
}

.big_box_div {
  width: 49%;
  padding-top: 5px;
  // margin-left: 20px;
  // margin-right: 20px;
}

/deep/.businessOutSource {
  //是否为外协费用背景色
  background-color: #b2f3fe;
  border: 1px solid #ddd !important;
}
.input_style {
  white-space: nowrap;
  // background-color: #fff;
  // border-color: #e4e7ed;
  color: #000;
  cursor: not-allowed;
  font-size: 14px;
  // border: 1px solid #e4e7ed;
  height: 40px;
  border-radius: 5px;
  line-height: 40px;
  padding: 0 10px;
}
/deep/.el-form-item {
  margin-bottom: 0px;
}

.radio-group {
  display: flex;
  align-items: center;
  height: 45px;
}
.radio-label {
  width: 100px;
  font-size: 14px;
}
/deep/.cellName {
  .cell {
    padding: 0;
  }
  padding: 0 !important;
}

.remark-box {
  padding-left: 10px;
}
/deep/.height-light {
  color: red;
  font-size: 20px;
}
.vatDialog {
  /deep/ .el-table th {
    border-color: #303133 !important;
  }
  /deep/ .el-table td {
    border-color: #303133 !important;
    height: 36px;
    line-height: 36px;
    color: #000;
  }
  /deep/ .el-table--group,
  /deep/ .el-table--border {
    border: 1px solid #303133 !important;
  }
  /deep/.cellContent {
    background-color: rgb(222, 235, 247) !important;
    text-align: center;
  }
  /deep/.cellTitle {
    background-color: rgb(189, 215, 238) !important;
  }
  /deep/.el-table tbody tr:hover > td {
    background-color: transparent;
  }
  /deep/.cellTitleFont {
    font-weight: 700;
  }
  /deep/.cellTitleAlign {
    text-align: center;
  }
  /deep/.clearBorder {
    border-right: none !important;
  }
}

.businessLinesDialog {
  /deep/ .el-table th {
    border-color: #303133 !important;
  }
  /deep/ .el-table td {
    border-color: #303133 !important;
    height: 32px;
    line-height: 32px;
  }
  /deep/ .el-table--group,
  /deep/ .el-table--border {
    border: 1px solid #303133 !important;
  }
  /deep/.clearBorder {
    border-right: none !important;
  }
  /deep/.content-text {
    color: #000;
  }
}
/deep/.el-descriptions__title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .title_right {
    color: red;
    font-weight: 900;
    font-size: 18px;
  }
}
.businessNameTooltip {
  white-space: nowrap;
  position: absolute;
  bottom: -30px;
  left: -40px;
  background-color: #000;
  color: #fff;
  z-index: 99999;
  line-height: 30px;
  border-radius: 5px;
  padding: 0 5px;
}
.businessEdit {
  padding-left: calc(100% - 105px);
}

.choice-button {
  display: flex;
}
</style>
